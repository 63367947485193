const app = (function () {
  const version = "v1";
  const defaultSaveKey = "appData";
  const dataTemplate = "data-template";

  let selectedData = {};

  let deferredPrompt = null;

  const defaultData = () => {
    return [];
  };

  /**
   * Get's the HTML element for the data, or clones the template
   * and adds it to the DOM if we're adding a new item.
   *
   * @param {Object} item the data object
   * @return {Element} The element for the data
   */
  const getCardForID = (id) => {
    const card = document.getElementById(id);
    if (card) {
      return card;
    }
    const tmpl = document.getElementById(dataTemplate);
    if (!tmpl) {
      return null;
    }
    const newCard = tmpl.cloneNode(true);
    newCard.setAttribute("id", id);
    document.querySelector("main").appendChild(newCard);
    newCard.removeAttribute("hidden");
    return newCard;
  };

  /**
   * Get's the latest data from the network.
   *
   * @param {string} params a list of parameters
   * @return {Object} the data, if the request fails, return null.
   */
  const fetchFromRemote = (params) => {
    return fetch(`/api/${version}/${params}`).then((response) => {
      return response.json();
    });
  };

  /**
   * Get's the cached data from the caches object.
   *
   * @param {string} params the parameters for the request.
   * @return {Object} The weather forecast, if the request fails, return null.
   */
  const fetchFromCache = (params) => {
    if (!("caches" in window)) {
      return null;
    }
    const url = `${window.location.origin}/api/${version}/${params}`;
    return caches.match(url).then((response) => {
      if (response) {
        return response.json();
      }
      return null;
    });
  };

  const render = (data) => {
    const card = getCardForID(data.id);

    if (!card) {
      return;
    }

    // TODO
  };

  /**
   * Gets the latest data and updates
   */
  const update = () =>
    Object.keys(selectedData).forEach((key) => {
      const data = selectedData[key];
      // Get the data from the network.
      fetchFromNetwork(data.id)
        .then((result) => {
          render(result);
          save(result);
        })
        .catch(() =>
          fetchFromCache(data.id)
            .then((result) => this.render(result))
            .catch((err) => {
              console.error("Error getting data from cache", err);
              return null;
            }),
        );
    });

  /**
   * Saves the list of data.
   *
   * @param {Object} data The list of data to save.
   */
  const save = (values, key = defaultSaveKey) => {
    const data = JSON.stringify(values);
    localStorage.setItem(key, data);
  };

  /**
   * Loads the list of saved fortune.
   *
   * @return {Array}
   */
  const load = (key = defaultSaveKey) => {
    let data = localStorage.getItem(key);
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (ex) {
        data = {};
      }
    }
    if (!data || Object.keys(data).length === 0) {
      data = defaultData();
    }
    return data;
  };

  /**
   * Initialize the app, gets the list of data from local storage, then
   * renders the initial data.
   */
  const init = () => {
    // Get the fortune list, and update the UI.
    selectedData = load();

    if (selectedData.length === 0) {
      update();
    }

    // Set up the event handlers for all of the buttons.
    const refresh = document.getElementById("butRefresh");
    const installer = document.getElementById("pwa");
    const install = document.getElementById("pwa-install");

    if (refresh) {
      refresh.addEventListener("click", update);
    }

    if (installer && install) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPrompt = e;
        installer.classList.toggle("hidden", false);
      });

      install.addEventListener("click", async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          const { outcome } = await deferredPrompt.userChoice;
          deferredPrompt = null;
        }
        installer.classList.toggle("hidden", true);
      });
      window.addEventListener("appinstalled", (event) => {
        console.log("👍", "appinstalled", event);
        // Clear the deferredPrompt so it can be garbage collected
        window.deferredPrompt = null;
      });
    }

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then((reg) => {
          console.log("Service worker registered.", reg);
        });
      });
    }
  };

  return { init };
})();

app.init();
