const app = (function() {

  const ANIM_DELAY = 200;

  let button = null;
  
  let intervalId = null;

  const displayFortune = (delay = 0) => {

    if (button) {
        button.addClass('fa-spin');
    }

    $.getJSON("/api/v1/random?s=true&n=200", data => {

    let categories = (data['categories'] || []);

    categories = categories.map((category) => {
        return category['name']
    });

    $("#fortunes-animation").animate({opacity:0}, delay)
        .queue(function(){
            $(this).find(".fortune").text(data['text']);
            $(this).find(".category").text(categories.join(", "));
            $(this).dequeue();
            if (button) {
                button.removeClass('fa-spin')
            }
        })
        .animate({opacity:1}, delay);
    }).fail(function (err) {
        console.log(err.responseText)
    });
  }

  const startTimer = () => {
    intervalId = setInterval(function() {
        displayFortune(ANIM_DELAY);
    }, 30 * 1000);
  }

  const init = () => {
    let nav = $("#header nav");

    let menu = $("#menu-toggle");

    menu.click(function() {
      nav.slideToggle({
        duration: ANIM_DELAY,
        start: function() {
          jQuery(this).css('display', 'flex');
        }
      });
    });

    if (menu.is(":visible")) {
      nav.hide()
    }
    
    window.onresize = function() {

      if (menu.is(":visible")) {
        nav.hide()
      } else {
        nav.show()
      }
    }

    button = $("#refresh-fortune, #menu-refresh");

    button.click(function() {
      clearInterval(intervalId);
      displayFortune(ANIM_DELAY);
      startTimer();
    });

    displayFortune();
    startTimer();
  }

  return { init }
}());

app.init();

